<template>
  <div class="d-flex justify-content-between">
    <div class="card">
      <div class="card__photo" :class="{ overlay: cardIcon }">
        <img :src="logo" alt="" />
        <!-- <img :src="coverImage" v-if="cardIcon" :alt="title"> -->
        <!-- <img :src="image" :alt="title" v-else> -->
        <div
          class="card__photo-icon"
          :to="link"
          @click="$emit('openVideo')"
          v-if="cardIcon"
        >
          <img src="../../assets/icons/play.svg" alt="" />
        </div>
      </div>

      <AppText
        size="20"
        weight="500"
        line-height="24"
        class="mb-20 font_Hmedium color_g1"
      >
        {{ $t(title) }}
      </AppText>
      <!--			<div class="flex-1">-->
      <!--				<AppText max-lines="6" size="14" weight="400" lineHeight="17" class="mb-30 color_grey">-->
      <!--					{{ $t(description) }}-->
      <!--				</AppText>-->
      <!--			</div>-->

      <template>
        <div class="d-flex justify-content-between">
          <app-button
            @click="vote(1)"
            theme="main"
            sides="14"
            fontSize="14"
            radius="12"
            height="40"
            class="nd_btn_progress font_Hsemibold"
          >
			  <a target="_blank" :href="item.link">
				  Онлайн выставка
			  </a>
          </app-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AppText from "./AppText";
import AppButton from "./AppButton";
import "../../assets/styles/components/app-card.scss";
import { voteStatusNames, voteStatuses } from "@/static/statusIds";

export default {
  name: "AppCard",
  components: { AppButton, AppText },
  props: {
    cover: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    cardIcon: {
      type: Boolean,
      default: false,
    },
    btn: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "",
    },
    instructions: {
      type: Boolean,
      default: false,
    },
    downloadTitle: {
      type: String,
      default: "",
    },
    downloadPath: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
      statusesNames: voteStatusNames,
      statuses: voteStatuses,
    };
  },
  methods: {
    vote(status) {
      this.$emit("onVote", status);
    },
  },
  computed: {
    coverImage() {
      return this.baseUrl + this.cover;
    },
  },
};
</script>

<style lang="scss" scoped>
.card__photo {
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 20px;
  margin-bottom: 20px;
}

.progress_wrapp {
  width: 100%;
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  .progress_block {
    position: absolute;
    width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(270deg, #29d08c -22.58%, #13b2e4 117.5%);
    border-radius: 8px 0 0 8px;
  }

  .progress_add {
    position: absolute;
    width: 30%;
    height: 100%;
    right: 0;
    top: 0;
    background: #fb6161;
    border-radius: 0 8px 8px 0;
  }
}

.progres_num {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.justify-content-between {
  justify-content: space-between;
}

.nd_btn_progress {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 183px;
  height: 49px;
  margin: auto;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: #e9edf1;
  width: 340px;
  padding: 30px;
}
</style>
