<template>
	<div class="hero-banner">
		<div class="hero-banner__bg">
			<img src="@/assets/images/beautiful-modern-building-modern-architecture.png" alt />
		</div>
		<div class="container">
			<div class="hero-banner__inner">
				<div class="hero-banner__title-content">
					<AppText
						:size="isMobileSmall ? 24 : isDesktopSmall ? 32 : 54"
						weight="600"
						:line-height="isMobileSmall ? 32 : isDesktopSmall ? 38 : 63"
						class="mb-40 font_Msemibold"
					>
						Онлайн-выставки промышленных предприятий
					</AppText>
					<AppText
						:size="isMobileSmall ? 18 : isDesktopSmall ? 18 : 18"
						weight="400"
						:line-height="isMobileSmall ? 21 : isDesktopSmall ? 21 : 21"
						class="mb-20 font_Hregular"
					>
						Виртуальная выставка - это онлайн платформа, позволяющая участвоватьна промышленных ярмарках с
						любой точки мира.
					</AppText>
					<AppText
						:size="isMobileSmall ? 18 : isDesktopSmall ? 18 : 18"
						weight="400"
						:line-height="isMobileSmall ? 21 : isDesktopSmall ? 21 : 21"
						class="font_Hregular"
					>
						Данная платформа является экономически исоциально- обоснованным решением организациии проведения
						выставок с меньшими расходами,при этом с большим охватом предпринимателей.
					</AppText>
				</div>

				<div>
					<BlockWrap
						:count="isMobileSmall ? 1 : isDesktopSmall ? 2 : isDesktopMedium ? 3 : 3"
						offset-y="20"
						offset-x="20"
					>
						<template v-for="item in links" class="">
							<ExhibitionsCard
								class="supplier-card"
								:title="item.text"
								:logo="item.image"
								:description="item.text"
								:item="item"
								btn
							/>
						</template>
					</BlockWrap>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AppText from "@/components/shared-components/AppText";
import AppButton from "@/components/shared-components/AppButton";
import FormLabel from "@/components/shared-components/FormLabel.vue";
import AppSlider from "@/components/shared-components/AppSlider";
import BlockWrap from "@/components/shared-components/BlockWrap";
import AppCard from "@/components/shared-components/AppCard";
import ExhibitionsCard from "@/components/shared-components/ExhibitionsCard";

export default {
	name: "HomeBanner",
	components: {
		AppButton,
		FormLabel,
		AppText,
		AppSlider,
		BlockWrap,
		AppCard,
		ExhibitionsCard,
	},
	data() {
		return {
			registerUrl: process.env.VUE_APP_ADMIN_URL + "cabinet/#/register",
			links: [
				{
					image: require("@/assets/images/exhibitions_1.svg"),
					link: "https://uzeltech.cooperation.uz/",
					text: "Ассоциации\n" + "“Узэлтехсаноат”",
				},
				{
					image: require("@/assets/images/exhibitions_2.svg"),
					link: "https://ngmk.cooperation.uz/",
					text: "АО “Навоийский\n" + "горно-металлургический\n" + "комбинат”",
				},
				{
					image: require("@/assets/images/exhibitions_3.svg"),
					link: "https://ung-cooperation.uz/",
					text: "АО “Узбекнефтегаз”",
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.hero-banner {
	position: relative;

	display: flex;
	align-items: center;
	flex-wrap: wrap;

	// background: var(--color-secondary);

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url("../../assets/images/svg/banner-element.svg");
		z-index: 2;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		opacity: 0.4;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		background: #434343;
		opacity: 0.55;
		// background: linear-gradient(266.93deg, #421E64 -27.85%, rgba(75, 37, 109, 0.8) 37.78%, rgba(86, 49, 120, 0.1) 107.52%);
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
		max-width: inherit !important;
		grid-column-gap: 105px;
		color: white;
		position: relative;
		z-index: 5;
		margin: 0 auto;
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// opacity: 0.55;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.home-search-content {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: white;
	border-radius: 26px;
	height: 52px;
	padding-left: 55px;
	padding-right: 6px;

	&_input {
		width: calc(100% - 150px);

		.app-input {
			border-style: none;
			padding-left: 0;

			::placeholder {
				color: #8e9195;
			}

			:-ms-input-placeholder {
				color: #8e9195;
			}

			::-ms-input-placeholder {
				color: #8e9195;
			}
		}
	}

	.search-icon {
		position: absolute;
		left: 20px;
	}

	.a-btn {
		// position: absolute;
		// right: 6px;
		white-space: nowrap;
	}

	.search-content {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
}

@media (max-width: 400px) {
	.hero-banner {
		min-height: unset;
		padding: 30px 0;
	}
}

.hero-banner {
	min-height: 710px;
	height: auto;
	width: 100%;

	&__title-content {
		max-width: 455px;
	}
}

@media (min-height: 1160px) {
	.hero-banner {
		min-height: calc(100vh - 452px) !important;
	}
}
</style>
